import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import VaibhavInternationalLogo from "src/assets/images/VaibhavInternationalLogo.jpg";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";
import { Props } from "./types";

const sidebarItems = [
  {
    id: 1,
    name: "Home",
    slug: "/",
    icon: "",
  },
  {
    id: 2,
    name: "Services",
    slug: "/services",
    icon: "",
  },
  {
    id: 3,
    name: "Pickup Request",
    slug: "/pickup-request",
    icon: "",
  },
  {
    id: 4,
    name: "About Us",
    slug: "/about-us",
    icon: "",
  },
  {
    id: 5,
    name: "Contact Us",
    slug: "/contact-us",
    icon: "",
  },
];

const Sidebar: React.FC<Props> = ({ showSidebar, handleSidebarToggle }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<number>(1);

  useEffect(() => {
    const initialTab = sidebarItems.find((item) => {
      return item.slug === pathname;
    });
    const id = initialTab ? initialTab.id : null;
    setActiveTab(id || 1);
  }, [pathname]);

  return (
    <div
      className={cx(classes.sidebar, {
        [classes.isActive]: showSidebar,
      })}
    >
      <div
        className={cx(classes.header, { [classes.sidebarBurger]: showSidebar })}
      >
        <img src={VaibhavInternationalLogo} className={classes.LogoIcon} />
        <div className={classes.closeIcon} onClick={handleSidebarToggle}>
          <CloseIcon />
        </div>
      </div>
      <div className={classes.sidebarContent}>
        {sidebarItems.map(({ id, name, icon, slug }) => {
          return (
            <div
              key={id}
              onClick={() => {
                setActiveTab(id);
                showSidebar && handleSidebarToggle();
                navigate(`${slug}`);
              }}
              className={cx(classes.sidebarItem, {
                [classes.active]: id === activeTab,
              })}
            >
              <span>{icon}</span>
              <Typography
                fontFamily="comic"
                size={20}
                fontWeight="regular"
                className={classes.itemNames}
              >
                {name}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
