import cs from "classnames";
import { Container } from "reactstrap";
import HeroSection from "src/assets/images/HeroSection.jpg";
import Hero from "src/components/Hero";
import classes from "./styles.module.scss";

const Home = () => {
  return (
    <>
      <Hero
        title={"Where Reliability Meets Global Reach"}
        description={
          "With our extensive network and unwavering commitment to reliability, we bridge the gap between distances, offering seamless courier services worldwide."
        }
        image={HeroSection}
      />

      <Container className={classes.container}>
        <div className={classes.home}>
          <div className={classes.content}>
            <h4 className={cs(classes.title, classes.title2)}>
              Vaibhav International - Fastest International Courier Service in
              Surat
            </h4>

            <div className={classes.description}>
              <p>
                At Vaibhav International, our dedication to transporting goods
                seamlessly across borders has positioned us as a premier global
                logistics provider. With an unwavering focus on efficiency,
                reliability, and client satisfaction, we've redefined the
                standards for international courier solutions.
              </p>
              <p>
                By choosing Vaibhav International, you gain access to swift,
                dependable shipping services that extend beyond mere delivery.
                Our comprehensive offerings include hassle-free customs
                clearance, real-time shipment tracking, and personalized support
                from our adept team based in Surat. Additionally, for those
                craving international delicacies, our specialized service
                ensures the fastest delivery of snacks to your doorstep,
                regardless of your global location.
              </p>
              <p>
                Whether you're a business expanding into new markets or an
                individual sending personal goods overseas, Vaibhav
                International is your trusted partner for international shipping
                from Surat and nearby regions. Reach out to us today to discover
                how we can optimize your global logistics strategy.
              </p>
            </div>
            <div>
              <h4 className={classes.title}>Insurance Options</h4>
              <div className={classes.description}>
                <p>
                  Providing insurance options for shipments adds an extra layer
                  of security for customers, safeguarding their goods against
                  loss or damage during transit. Insurance coverage can vary
                  based on the value and nature of the items being shipped. Some
                  key points to consider and communicate to customers include:
                </p>
                <p>
                  <ul className={classes.descriptionList}>
                    <li>
                      Types of insurance available (e.g., standard coverage,
                      additional coverage for high-value items).
                    </li>
                    <li>Coverage limits and exclusions.</li>
                    <li>Claims process in case of loss or damage.</li>
                    <li>
                      Cost of insurance relative to the value of the shipment.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div>
              <h4 className={classes.title}>Customs Expertise</h4>
              <div className={classes.description}>
                <p>
                  Navigating customs regulations can be a daunting task for many
                  customers, especially when shipping internationally. Offering
                  expertise in this area can streamline the process and
                  alleviate concerns. Here are some aspects to highlight:
                </p>
                <p>
                  <ul className={classes.descriptionList}>
                    <li>
                      In-depth knowledge of customs regulations in various
                      countries.
                    </li>
                    <li>
                      Assistance with completing necessary documentation and
                      paperwork.
                    </li>
                    <li>
                      Strategies for minimizing delays and avoiding customs
                      issues.
                    </li>
                    <li>
                      Dedicated support team to address any customs-related
                      queries or challenges.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div>
              <h4 className={classes.title}>Technology Integration</h4>
              <div className={classes.description}>
                <p>
                  Leveraging technology can enhance the efficiency and
                  transparency of your shipping operations, providing added
                  value to customers. Here are some technological features to
                  highlight:
                </p>
                <p>
                  <ul className={classes.descriptionList}>
                    <li>
                      Real-time shipment tracking through online platforms or
                      mobile apps.
                    </li>
                    <li>
                      Integration with customer relationship management (CRM)
                      systems for personalized communication and support.
                    </li>
                    <li>
                      Advanced analytics and reporting tools for optimizing
                      logistics processes.
                    </li>
                    <li>
                      Automated alerts and notifications to keep customers
                      informed about the status of their shipments.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
