import { Container } from "reactstrap";
import AboutUsSection from "src/assets/images/AboutUsSection.jpg";
import Hero from "src/components/Hero";
import classes from "./styles.module.scss";

const AboutUs = () => {
  return (
    <>
      <Hero
        title={"Empowering Connections, Delivering Dreams"}
        description={
          "Your bridge to seamless global connections. Join us in simplifying international shipping for a world of possibilities."
        }
        image={AboutUsSection}
        textColor={classes.titleColor}
      />

      <Container className={classes.container}>
        <div className={classes.AboutUs}>
          <div className={classes.content}>
            <h4 className={classes.title}>Mission and Vision</h4>
            <div className={classes.description}>
              <p>
                At Vaibhav International, our vision extends far beyond borders
                and boundaries. We aspire to be more than just a logistics
                company, we aim to be the cornerstone of global connectivity and
                innovation. Our presence in over 220 countries and territories
                speaks volumes, but it's our unwavering commitment to excellence
                that truly sets us apart.
              </p>
              <p>
                Our vision is simple yet profound: to be The Logistics Company
                for the World. This encompasses not only our expansive global
                reach but also our diverse portfolio of logistics solutions.
                From international express deliveries to warehousing, customs
                brokerage, and freight forwarding, we offer a comprehensive
                suite of services to meet the evolving needs of our customers.
                Central to our vision is the idea of being the logistics
                provider of choice - the trusted partner that individuals,
                businesses, and investors turn to first. Our mission statement,
                "Excellence. Simply Delivered.", encapsulates our dedication to
                simplifying the lives of our customers, empowering our
                employees, and delivering value to our investors.
              </p>
              <p>
                We believe in making our customers, employees, and investors
                more successful, while also making a positive contribution to
                the world. Every action we take is guided by principles of
                respect, integrity, and accountability, ensuring that we achieve
                our results in a responsible and sustainable manner.
              </p>
              <p>
                Join us on our journey to redefine the future of logistics -
                where excellence is not just a goal but a way of life, and where
                every delivery is more than just a package, it's a promise
                fulfilled. At Vaibhav International, we don't just deliver
                goods, we deliver possibilities.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AboutUs;
