import cs from "classnames";
import React from "react";
import { Container } from "reactstrap";
import { useWindowSize } from "src/utility/useWindowSize";
import Tracker from "../Tracker";
import classes from "./hero.module.scss";
import { Props } from "./types";

const Hero: React.FC<Props> = ({
  title,
  description,
  image,
  textColor,
  headingWrapper,
  gridLayout,
}) => {
  const { width = 0 } = useWindowSize();
  const isMobile = width < 768;

  const renderChildren = () => {
    return (
      <div className={cs(classes.titleWrapper, headingWrapper)}>
        <div>
          <h5 className={cs(classes.title, textColor)}>{title}</h5>
        </div>
        <div>
          <p className={cs(classes.description, textColor)}>{description}</p>
        </div>

        {!isMobile && <Tracker />}
      </div>
    );
  };

  return (
    <>
      <div
        className={classes.heroWrapper}
        style={{ backgroundImage: gridLayout ? "" : `url(${image})` }}
      >
        {gridLayout && <img className={classes.gridImage} src={image} />}
        {gridLayout ? (
          renderChildren()
        ) : (
          <Container>{renderChildren()}</Container>
        )}
      </div>
      {isMobile && (
        <div className={classes.trackerWrapper}>
          <Container className={classes.trackerContainer}>
            <Tracker />
          </Container>
        </div>
      )}
    </>
  );
};

export default Hero;
