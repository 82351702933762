import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import PickupRequest from "./pages/PickupRequest";
import Services from "./pages/Services";
import TrackShipment from "./pages/TrackShipment";

export const publicRoutes = [
  {
    path: "/",
    name: "home",
    component: Home,
    layout: "private",
  },
  {
    path: "/about-us",
    name: "about us",
    component: AboutUs,
    layout: "private",
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    layout: "private",
  },
  {
    path: "/contact-us",
    name: "contact us",
    component: ContactUs,
    layout: "private",
  },
  {
    path: "/pickup-request",
    name: "pickup request",
    component: PickupRequest,
    layout: "private",
  },
  {
    path: "/track-request/:id",
    name: "track request",
    component: TrackShipment,
    layout: "private",
  },
];
