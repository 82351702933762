import { Navigate, Route, Routes } from "react-router";
import PublicLayout from "src/layout/PublicLayout";
import classes from "./App.module.scss";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <div className={classes.app}>
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<PublicLayout />} />
        <Route path="*" element={<Navigate to="/auth" replace />} />
      </Routes>
    </div>
  );
};

export default App;
