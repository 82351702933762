import cs from "classnames";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container, Nav, NavItem } from "reactstrap";
import { ReactComponent as BurgerIcon } from "src/assets/icons/hamburger-icon.svg";
import VaibhavInternationalLogo from "src/assets/images/VaibhavInternationalLogo.jpg";
import Button from "src/components/Button/index";
import { useWindowSize } from "src/utility/useWindowSize";
import { navItems } from "./NavItems";
import classes from "./header.module.scss";

interface Props {
  children?: React.ReactNode;
  openSidebar: () => void;
}

const Header: React.FC<Props> = ({ openSidebar }) => {
  const { width = 0 } = useWindowSize();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [navActiveItem, setNavActiveItem] = useState("");
  const isMobile = width < 768;

  useEffect(() => {
    setNavActiveItem(pathname);
  }, [pathname]);
  return (
    <div className={classes.headerWrapper}>
      <Container>
        <div className={classes.container}>
          <div
            className={classes.Logo}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={VaibhavInternationalLogo} className={classes.LogoIcon} />
          </div>

          <div className={classes.tabsWrapper}>
            {!isMobile ? (
              <Nav className={classes.nav}>
                {navItems.map(({ name, link }, index) => {
                  return (
                    <NavItem key={index} className={classes.navItem}>
                      <NavLink
                        to={link}
                        className={cs(classes.navLink, {
                          [classes.isActive]: link === navActiveItem,
                        })}
                      >
                        {name}
                      </NavLink>
                    </NavItem>
                  );
                })}

                <Link to="/contact-us">
                  <Button className={classes.btnContactUs}>Contact Us</Button>
                </Link>
              </Nav>
            ) : (
              <div className={classes.burger}>
                <BurgerIcon onClick={openSidebar} height={24} width={24} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
