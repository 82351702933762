import React from "react";
// import classes from "./styles.module.scss";
import { Props, SortByProps } from "./types";

const TableHeader: React.FC<Props> = ({ column, index, handleSort }) => {
  return (
    <th
      {...column.getHeaderProps()}
      key={index}
      onClick={() => {
        handleSort(column.id as SortByProps);
      }}
    >
      {column.render("Header")}
      {/* <span className={classes.carets}>
        <i className="fa fa-caret-up" />
        <i className="fa fa-caret-down" />
      </span> */}
    </th>
  );
};

export default TableHeader;
