import axios from "axios";
import cs from "classnames";
import { Form, Formik, FormikProps } from "formik";
import { ChangeEvent, useState } from "react";
import { Container, FormGroup } from "reactstrap";
import * as Yup from "yup";
import ContactUsSection from "src/assets/images/ContactUsSection.jpg";
import Button from "src/components/Button";
import Hero from "src/components/Hero";
import Input from "src/components/Input";
import { useWindowSize } from "src/utility/useWindowSize";
import Address from "../Address";
import classes from "./styles.module.scss";
import { TProps } from "./types";

const ContactUs = () => {
  const contactUsSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    phone: Yup.number().required("Phone is required."),
    message: Yup.string().required("Message is required"),
  });
  const { width = 0 } = useWindowSize();
  const isMobile = width < 768;
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className={classes.ContactUs}>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          }}
          enableReinitialize
          validationSchema={contactUsSchema}
          onSubmit={async (values: TProps, { resetForm }) => {
            const baseId = "app1dvLh70N3t4XHn";
            setLoading(true);
            try {
              await axios.post(
                `https://api.airtable.com/v0/${baseId}/contact`,
                {
                  fields: values,
                },
                {
                  headers: {
                    Authorization: `Bearer patRuhCGdM4XewI4E.037947ecbbf58db9d1555e60b545cce15339b95d29fa925c542586bba776b725`,
                    "Content-Type": "application/json",
                  },
                }
              );
              // Handle success or navigate to another page
            } catch (error) {
              console.error("Error creating record:", error);
              // Handle error
            }
            setLoading(false);
            resetForm();
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            isValid,
            dirty,
          }: FormikProps<TProps>) => {
            return (
              <>
                <Container
                  className={cs({
                    [classes.contactUsContainer]: isMobile,
                  })}
                >
                  <div className={classes.contactInfo}>
                    <Hero
                      title={"Empowering Connections, Delivering Dreams"}
                      description={
                        "Your bridge to seamless global connections. Join us in simplifying international shipping for a world of possibilities."
                      }
                      image={ContactUsSection}
                      headingWrapper={classes.headingWrapper}
                      gridLayout={!isMobile}
                    />
                    {isMobile && <Address />}

                    <Form>
                      <div className={classes.content}>
                        <div className={classes.titleWrapper}>
                          <h4 className={classes.title}>
                            Start the conversation by filling out the form
                            below.
                          </h4>
                        </div>
                        <div className={classes.contactWrapper}>
                          <div className={classes.names}>
                            <FormGroup className={classes.formGroup}>
                              <Input
                                inputGroupClassName={classes.inputGroupStyle}
                                labelClassName={classes.label}
                                className={classes.contactInput}
                                required={true}
                                label="First Name"
                                placeholder="First Name"
                                value={values.firstName}
                                onBlur={handleBlur}
                                error={errors.firstName}
                                touched={touched.firstName}
                                onChange={handleChange}
                                name="firstName"
                              />
                            </FormGroup>
                            <FormGroup className={classes.formGroup}>
                              <Input
                                inputGroupClassName={classes.inputGroupStyle}
                                labelClassName={classes.label}
                                className={classes.contactInput}
                                required={true}
                                label="Last Name"
                                placeholder="Last Name"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.lastName}
                                touched={touched.lastName}
                                name="lastName"
                              />
                            </FormGroup>

                            <FormGroup className={classes.formGroup}>
                              <Input
                                inputGroupClassName={classes.inputGroupStyle}
                                labelClassName={classes.label}
                                className={classes.contactInput}
                                required={true}
                                label="Email"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.email}
                                touched={touched.email}
                                name="email"
                              />
                            </FormGroup>
                            <FormGroup className={classes.formGroup}>
                              <Input
                                inputGroupClassName={classes.inputGroupStyle}
                                labelClassName={classes.label}
                                className={classes.contactInput}
                                required={true}
                                label="Mobile No."
                                placeholder="Mobile No."
                                value={values.phone}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const numericValue =
                                    event.target.value.replace(/[^0-9]/g, "");
                                  setFieldValue("phone", numericValue);
                                }}
                                maxLength={10}
                                onBlur={handleBlur}
                                error={errors.phone}
                                touched={touched.phone}
                                name="phone"
                              />
                            </FormGroup>

                            <FormGroup className={classes.formGroup}>
                              <Input
                                inputGroupClassName={classes.inputGroupStyle}
                                labelClassName={classes.label}
                                className={classes.contactInput}
                                required={true}
                                label="Message"
                                placeholder="Message"
                                type="textarea"
                                rows={5}
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.message}
                                touched={touched.message}
                                name="message"
                              />
                            </FormGroup>

                            <FormGroup className={classes.btnSubmit}>
                              <Button
                                disabled={!isValid || !dirty}
                                buttonClassName={classes.submitButton}
                                type="submit"
                                className={classes.contactBtnSubmit}
                                loading={loading}
                              >
                                Submit
                              </Button>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Container>
              </>
            );
          }}
        </Formik>
      </div>
      {!isMobile && <Address />}
    </>
  );
};

export default ContactUs;
