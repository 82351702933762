import { Container } from "reactstrap";
import location from "src/assets/svg/location.svg";
import mail from "src/assets/svg/mail.svg";
import phone from "src/assets/svg/phone.svg";
import classes from "./styles.module.scss";

const Address = () => {
  return (
    <Container>
      <div className={classes.contactDetails}>
        <div className={classes.detailsContent}>
          <div className={classes.iconWrapper}>
            <div>
              <img
                src={location}
                alt="location"
                className={classes.contactIcon}
              />
            </div>
            <div className={classes.contactTitleWrapper}>
              <h5 className={classes.contactTitle}>Address</h5>
            </div>
          </div>
          <span className={classes.contactDescription}>
            Shop No. 10, Virat Apartment, Diwalibaugh, nr. Dhiraj Sons, Athwa
            Gate, Surat, Gujarat 395007
          </span>
        </div>
        <div className={classes.detailsContent}>
          <div className={classes.iconWrapper}>
            <div>
              <img src={phone} alt="phone" className={classes.contactIcon} />
            </div>
            <div className={classes.contactTitleWrapper}>
              <h5 className={classes.contactTitle}>Call</h5>
            </div>
          </div>
          <span className={classes.contactDescription}>+91 9825476746</span>
        </div>
        <div className={classes.detailsContent}>
          <div className={classes.iconWrapper}>
            <div>
              <img src={mail} alt="mail" className={classes.contactIcon} />
            </div>
            <div className={classes.contactTitleWrapper}>
              <h5 className={classes.contactTitle}>Email </h5>
            </div>
          </div>
          <span className={classes.contactDescription}>
            vaibhavinternational321@gmail.com
          </span>
        </div>
      </div>
    </Container>
  );
};

export default Address;
