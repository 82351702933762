import axios from "axios";
import cs from "classnames";
import dayjs from "dayjs";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import Loader from "src/components/Loader";
import Table from "src/components/Table";
import Tracker from "src/components/Tracker";
import { useWindowSize } from "src/utility/useWindowSize";
import classes from "./styles.module.scss";
import { TOrderData, TOrderFields, TTrackData, TTrackFields } from "./types";

const TrackShipment = () => {
  const { id } = useParams();
  const [trackData, setTrackData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { width = 0 } = useWindowSize();
  const isMobile = width < 768;
  useEffect(() => {
    const fetchData = async () => {
      const baseId = "app1dvLh70N3t4XHn";
      const baseTrackingURL = `https://api.airtable.com/v0/${baseId}/tracking`;
      const baseOrdersURL = `https://api.airtable.com/v0/${baseId}/orders`;
      const formula = encodeURIComponent(`{Number} = ${id}`);
      const url = `${baseOrdersURL}?filterByFormula=${formula}`;
      setLoading(true);
      try {
        const responseTracking = await axios.get(baseTrackingURL, {
          headers: {
            Authorization: `Bearer patRuhCGdM4XewI4E.037947ecbbf58db9d1555e60b545cce15339b95d29fa925c542586bba776b725`,
          },
        });
        const responseOrders = await axios.get(url, {
          headers: {
            Authorization: `Bearer patRuhCGdM4XewI4E.037947ecbbf58db9d1555e60b545cce15339b95d29fa925c542586bba776b725`,
          },
        });
        const trakingData = responseTracking.data;
        const orderData = responseOrders.data;
        const trakingRecords = get(trakingData, "records", []);
        const orderRecords = get(orderData, "records", []);
        if (trakingRecords.length > 0) {
          setTrackData(
            trakingRecords.map((record: TTrackData) => record.fields)
          );
        } else {
          setTrackData([]);
        }

        if (orderRecords.length > 0) {
          setOrderData(orderRecords.map((record: TOrderData) => record.fields));
        } else {
          setOrderData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const trackingData = trackData.filter((data) => {
    const trackNo: string | undefined = data["Number (from order_number)"][0];
    if (trackNo !== undefined) {
      return trackNo === String(id);
    }
    return false;
  });

  if (loading) return <Loader wrapperClassName={classes.loader}></Loader>;

  if (trackingData.length <= 0) {
    return (
      <div className={classes.infoNotFound}>
        <h1>No tracking data found</h1>
        <Link to="/" className={classes.backHome}>
          go back
        </Link>
      </div>
    );
  }

  return (
    <Container>
      <Tracker
        titleWrapper={classes.titleWrapper}
        textColor={classes.textColor}
      />
      <div className={classes.trackShipment}>
        <div className={classes.tracking}>
          {orderData.map((data: TOrderFields) => {
            return (
              <div key={data?.ID} className={classes.content}>
                <div>
                  <p
                    className={cs(classes.trackingContent, classes.trackingNo)}
                  >
                    Tracking No :{" "}
                    <span className={classes.trackingDetails}>{id}</span>
                  </p>
                  <p className={classes.trackingContent}>
                    Ship Date :{" "}
                    <span className={classes.trackingDetails}>
                      {data["Created On"] &&
                        dayjs(data["Created On"]).format("DD-MM-YYYY")}
                    </span>
                  </p>
                  <p className={classes.trackingContent}>
                    Destination :{" "}
                    <span className={classes.trackingDetails}>
                      {data?.destination}
                    </span>
                  </p>
                  {!isMobile && (
                    <p
                      className={cs(
                        classes.trackingContent,
                        classes.trackingContent2
                      )}
                    >
                      Shipment Travel History for Tracking No. :{" "}
                      <span className={classes.trackingDetails}>{id}</span>
                    </p>
                  )}
                </div>
                <div>
                  <p className={classes.trackingContent}>
                    Consignee Name :{" "}
                    <span className={classes.trackingDetails}>
                      {data?.consignee_name}
                    </span>
                  </p>
                  <p className={classes.trackingContent}>
                    Status :{" "}
                    <span className={classes.status}>{data?.status}</span>
                  </p>
                  {isMobile && (
                    <p
                      className={cs(
                        classes.trackingContent,
                        classes.trackingContent2
                      )}
                    >
                      Shipment Travel History for Tracking No. :{" "}
                      <span className={classes.trackingDetails}>{id}</span>
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.shipmentHistory}>
          {!isMobile ? (
            <div className={classes.historyTable}>
              <Table
                tableHeaderClassname={classes.tableHeaderStyles}
                data={trackingData}
                loading={loading}
                handleSort={() => {}}
                noDataIndication={
                  <div className="d-flex  justify-content-center py-4">
                    No data found
                  </div>
                }
                columns={[
                  {
                    Header: "Date",
                    accessor: "",
                    //eslint-disable-next-line react/no-multi-comp
                    Cell: (props) => {
                      const date = get(props, "row.original.time", "-");
                      return (
                        <p className="mb-0">
                          {dayjs(date || "-").format("DD-MM-YYYY")}
                        </p>
                      );
                    },
                  },
                  {
                    Header: "Time",
                    accessor: "time",
                    //eslint-disable-next-line react/no-multi-comp
                    Cell: (props) => {
                      return (
                        <p className="mb-0">
                          {dayjs(props?.value || "-").format("HH:mm:ss")}
                        </p>
                      );
                    },
                  },
                  {
                    Header: "Location",
                    accessor: "location",
                    //eslint-disable-next-line react/no-multi-comp
                    Cell: (props) => {
                      return <p className="mb-0">{props?.value || "-"}</p>;
                    },
                  },
                  {
                    Header: "Activity",
                    accessor: "activity",
                    //eslint-disable-next-line react/no-multi-comp
                    Cell: (props) => {
                      return <p className="mb-0">{props?.value || "-"}</p>;
                    },
                  },
                ]}
              />
            </div>
          ) : (
            <div className={classes.shipmentDetails}>
              {trackingData.map((value: TTrackFields) => {
                return (
                  <div key={value.id} className={classes.shipmentContent}>
                    <div className={classes.descriptionWrapper}>
                      <span className={classes.description}>
                        <span className={classes.title}> Date </span>
                        {value.time
                          ? dayjs(value.time).format("DD-MM-YYYY")
                          : "-"}
                      </span>
                      <span className={classes.description}>
                        <span className={classes.title}> Time </span>
                        {value.time
                          ? dayjs(value.time).format("HH:mm:ss")
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.descriptionWrapper}>
                      <span className={classes.description}>
                        <span className={classes.title}> Location </span>
                        {value.location ? value.location : "-"}
                      </span>
                      <span className={classes.description}>
                        <span className={classes.title}> Activity</span>{" "}
                        {value.activity ? value.activity : "-"}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TrackShipment;
