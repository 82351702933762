export const navItems = [
  {
    id: "home",
    name: "Home",
    link: "/",
  },
  {
    id: "services",
    name: "Services",
    link: "/services",
  },
  {
    id: "pickup-request",
    name: "Pickup Request",
    link: "/pickup-request",
  },
  {
    id: "about-us",
    name: "About Us",
    link: "/about-us",
  },
];
