import axios from "axios";
import cs from "classnames";
import { Form, Formik, FormikProps } from "formik";
import { ChangeEvent, useState } from "react";
import { Button, Container, FormGroup } from "reactstrap";
import * as Yup from "yup";
import PickupRequestSection from "src/assets/images/PickupRequestSection.jpg";
import Hero from "src/components/Hero";
import Input from "src/components/Input";
import { useWindowSize } from "src/utility/useWindowSize";
import classes from "./styles.module.scss";
import { TPickupRequestProps } from "./types";

const PickupRequest = () => {
  const pickupRequestSchema = Yup.object().shape({
    requesterName: Yup.string().required("Requester Name is required"),
    requesterPhone: Yup.number().required("Requester Phone is required."),
    pickupCity: Yup.string().required("Pickup City is required"),
    pickupAddress: Yup.string().required("Pickup Address is required"),
  });
  const [loading, setLoading] = useState(false);
  const { width = 0 } = useWindowSize();

  const isMobile = width < 768;

  return (
    <>
      <div className={classes.pickupRequest}>
        <Formik
          initialValues={{
            requesterName: "",
            requesterPhone: "",
            requesterEmail: "",
            pickupCity: "",
            pickupAddress: "",
          }}
          enableReinitialize
          validationSchema={pickupRequestSchema}
          onSubmit={async (values: TPickupRequestProps, { resetForm }) => {
            const baseId = "app1dvLh70N3t4XHn";
            setLoading(true);
            try {
              await axios.post(
                `https://api.airtable.com/v0/${baseId}/pickup`,
                {
                  fields: values,
                },
                {
                  headers: {
                    Authorization: `Bearer patRuhCGdM4XewI4E.037947ecbbf58db9d1555e60b545cce15339b95d29fa925c542586bba776b725`,
                    "Content-Type": "application/json",
                  },
                }
              );
              // Handle success or navigate to another page
            } catch (error) {
              console.error("Error creating record:", error);
              // Handle error
            }
            setLoading(false);
            resetForm();
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            isValid,
            dirty,
          }: FormikProps<TPickupRequestProps>) => {
            return (
              <Container
                className={cs({
                  [classes.pickupContainer]: isMobile,
                })}
              >
                <div className={classes.pickupWrapper}>
                  <Hero
                    title={"Enabling Pickups, Linking Destinations"}
                    description={
                      "Your bridge to seamless global connections. Join us in simplifying international shipping for a world of possibilities."
                    }
                    image={PickupRequestSection}
                    textColor={classes.textColor}
                    headingWrapper={classes.headingWrapper}
                    gridLayout={!isMobile}
                  />

                  <Form>
                    <div className={classes.content}>
                      <div className={classes.titleWrapper}>
                        <h4 className={classes.title}>
                          Fill out the form below to schedule your pickup.
                        </h4>
                      </div>
                      <div className={classes.contactWrapper}>
                        <div className={classes.names}>
                          <FormGroup className={classes.formGroup}>
                            <Input
                              inputGroupClassName={classes.inputGroupStyle}
                              labelClassName={classes.label}
                              className={classes.pickupInput}
                              required={true}
                              label="Requester Name"
                              placeholder="Requester Name"
                              value={values.requesterName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.requesterName}
                              touched={touched.requesterName}
                              name="requesterName"
                            />
                          </FormGroup>
                          <FormGroup className={classes.formGroup}>
                            <Input
                              inputGroupClassName={classes.inputGroupStyle}
                              className={classes.pickupInput}
                              labelClassName={classes.label}
                              required={true}
                              label="Requester Mobile No"
                              placeholder="Requester Mobile No"
                              value={values.requesterPhone}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                const numericValue = event.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setFieldValue("requesterPhone", numericValue);
                              }}
                              maxLength={10}
                              onBlur={handleBlur}
                              error={errors.requesterPhone}
                              touched={touched.requesterPhone}
                              name="requesterPhone"
                            />
                          </FormGroup>

                          <FormGroup className={classes.formGroup}>
                            <Input
                              inputGroupClassName={classes.inputGroupStyle}
                              labelClassName={classes.label}
                              className={classes.pickupInput}
                              required={true}
                              label="Pickup City"
                              placeholder="Pickup City"
                              value={values.pickupCity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.pickupCity}
                              touched={touched.pickupCity}
                              name="pickupCity"
                            />
                          </FormGroup>
                          <FormGroup className={classes.formGroup}>
                            <Input
                              inputGroupClassName={classes.inputGroupStyle}
                              labelClassName={classes.label}
                              className={classes.pickupInput}
                              label="Requester E-Mail"
                              placeholder="Requester E-Mail"
                              value={values.requesterEmail}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.requesterEmail}
                              touched={touched.requesterEmail}
                              name="requesterEmail"
                            />
                          </FormGroup>

                          <FormGroup className={classes.formGroup}>
                            <Input
                              inputGroupClassName={classes.inputGroupStyle}
                              labelClassName={classes.label}
                              className={classes.pickupInput}
                              required={true}
                              label="Pickup Address"
                              type="textarea"
                              rows={5}
                              placeholder="Message"
                              value={values.pickupAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.pickupAddress}
                              touched={touched.pickupAddress}
                              name="pickupAddress"
                            />
                          </FormGroup>
                          <FormGroup className={classes.btnSubmit}>
                            <Button
                              disabled={!isValid || !dirty}
                              buttonClassName={classes.submitButton}
                              className={classes.pickupBtnSubmit}
                              type="submit"
                              loading={loading}
                            >
                              Submit
                            </Button>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </Container>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default PickupRequest;
