import cx from "classnames";
import React, { CSSProperties } from "react";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Typography: React.FC<Props> = ({
  size = 16,
  fontWeight = "regular",
  fontFamily = "jakarta",
  children,
  className = "",
  ...rest
}) => {
  const getTypography = () => {
    return (
      <p
        style={{ "--size": `${size}px` } as CSSProperties}
        className={cx(
          classes.typography,
          classes[fontFamily],
          classes[fontWeight],
          {
            [className]: className,
          }
        )}
        {...rest}
      >
        {children}
      </p>
    );
  };
  return getTypography();
};

export default Typography;
