import cs from "classnames";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { publicRoutes } from "src/routes";
import { useWindowSize } from "src/utility/useWindowSize";
import { getRoutes } from "src/utility/utils";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import classes from "./styles.module.scss";

const PublicLayout: React.FC = () => {
  const { width = 0 } = useWindowSize();

  const isMobile = width < 768;
  const [showSidebar, setShowSidebar] = useState(false);

  const openSidebar = () => setShowSidebar(true);

  useEffect(() => {
    if (!isMobile) {
      setShowSidebar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const handleSidebarToggle = () => setShowSidebar(!showSidebar);

  return (
    <div className={classes.container}>
      {isMobile && (
        <Sidebar
          showSidebar={showSidebar}
          handleSidebarToggle={handleSidebarToggle}
        />
      )}
      <div
        className={cs(classes.mainContainer, {
          [classes.hideContainer]: showSidebar,
        })}
      >
        <Header openSidebar={openSidebar} />
        <div className={classes.content}>
          <div className={classes.contentWrapper}>
            <Routes>
              {getRoutes(publicRoutes)}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <div className={classes.footer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
