import cx from "classnames";
import React from "react";
import {
  Pagination as BasePagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useWindowSize } from "src/utility/useWindowSize";
import classes from "./styles.module.scss";
import { usePagination } from "./usePagination";

type Props = {
  pageIndex: number;
  length: number;
  pageSize: number;
  handlePageChange: (pageNumber: number) => void;
  onNextHandler: () => void;
  onPreviousHandler: () => void;
  from: number;
  to: number;
};

const Pagination: React.FC<Props> = ({
  pageIndex,
  length,
  pageSize,
  handlePageChange,
  onPreviousHandler,
  onNextHandler,
  // from = 1,
  // to = 10,
}) => {
  const pagesCount = Math.ceil(length / pageSize);
  const { width = 0 } = useWindowSize();
  const isMobile = width < 768;

  const paginationRange =
    usePagination({
      currentPage: pageIndex,
      totalCount: length,
      siblingCount: isMobile ? 1 : 2,
      pageSize: pageSize,
    }) || [];

  return (
    <>
      <div className={cx("pagination-wrapper", classes.paginationWrapper)}>
        <div className={classes.dataShow}>
          {/* <p className="mb-0">
            Showing {from} to {to} of {length} entries
          </p> */}
        </div>
        <BasePagination
          aria-label="Page navigation example"
          className={classes.paginationElement}
        >
          <PaginationItem disabled={pageIndex <= 1}>
            <PaginationLink onClick={onPreviousHandler} previous />
          </PaginationItem>

          {paginationRange.map((page, i) => {
            if (page === "...") {
              return <span key={i}>...</span>;
            }

            return (
              <PaginationItem active={page === pageIndex} key={i}>
                <PaginationLink
                  onClick={() => handlePageChange(page as number)}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}

          <PaginationItem disabled={pageIndex >= pagesCount}>
            <PaginationLink onClick={onNextHandler} next />
          </PaginationItem>
        </BasePagination>
      </div>
    </>
  );
};
export default Pagination;
