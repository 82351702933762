import cs from "classnames";
import { Form, Formik, FormikProps } from "formik";
import get from "lodash/get";
import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ReactComponent as SearchIcon } from "src/assets/svg/search.svg";
import Button from "../Button";
import Input from "../Input";
import classes from "./tracker.module.scss";
import { Props } from "./types";

const Tracker: React.FC<Props> = ({ textColor, titleWrapper }) => {
  const { pathname } = useLocation();
  const trackMatch = matchPath({ path: "/track-request/:id" }, pathname);
  const trackNo = get(trackMatch, "params.id", "");
  const navigate = useNavigate();

  const trackNoSchema = Yup.object().shape({
    trackNo: Yup.string().required("Tracking Number is required"),
  });
  return (
    <div className={classes.trackerWrapper}>
      <Formik
        initialValues={{
          trackNo: "" || trackNo,
        }}
        enableReinitialize
        validationSchema={trackNoSchema}
        onSubmit={async (values: Props) => {
          if (values) {
            navigate(`/track-request/${values.trackNo}`);
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          dirty,
        }: FormikProps<Props>) => {
          return (
            <Form>
              <div className={cs(titleWrapper)}>
                <div>
                  <h5 className={classes.shipmentTitle}>Track your Shipment</h5>
                </div>
                <div>
                  <h6 className={classes.shipmentNumber}>
                    <span className={cs(textColor)}>Track by</span> Tracking
                    Number
                  </h6>
                </div>
              </div>
              <div>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="trackNo"
                  className={classes.trackingInput}
                  value={values.trackNo}
                  error={errors.trackNo}
                  touched={touched.trackNo}
                  placeholder="Enter Tracking Number"
                  rightIcon={
                    <Button
                      type="submit"
                      buttonClassName={classes.searchIcon}
                      disabled={!isValid || !dirty}
                    >
                      <SearchIcon />
                    </Button>
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Tracker;
