import cx from "classnames";
import React from "react";
import { Button as BaseButton } from "reactstrap";
import Loader from "../Loader";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Button: React.FC<Props> = (Props) => {
  const {
    variant = "fill",
    rightIcon,
    leftIcon,
    children,
    buttonClassName = "",
    loading = false,
    disabled = false,
    buttonColor = "primary",
    size = "md",
    ...rest
  } = Props;

  const hasIcons = !!rightIcon || !!leftIcon || loading;
  const renderLeftIcon = () => {
    if (!leftIcon) return;
    return (
      <span className={cx("btn-inner--icon", classes.leftIconStyling)}>
        {leftIcon}
      </span>
    );
  };

  const renderRightIcon = () => {
    if (!rightIcon) return;
    return (
      <span className={cx("btn-inner--icon", classes.rightIconStyling)}>
        {rightIcon}
      </span>
    );
  };

  const renderButton = () => {
    if (!children) return;
    return (
      <span
        className={cx({
          "btn-inner--text d-inline-flex align-items-center gap-2": hasIcons,
        })}
      >
        {children}
        {loading && <Loader className={classes.loader} />}
      </span>
    );
  };

  if (variant === "link") {
    return (
      <span
        className={cx(classes.link, classes[`${buttonColor}-link`])}
        {...rest}
      >
        {renderLeftIcon()}
        {children}
        {renderRightIcon()}
      </span>
    );
  }

  return (
    <BaseButton
      className={cx(
        classes.buttonStyling,
        {
          [buttonClassName]: buttonClassName,
          "btn-icon": hasIcons,
          [classes.disabled]: disabled,
        },
        classes[`${buttonColor}-${variant}`],
        classes[size]
      )}
      size={size}
      disabled={disabled || loading}
      {...rest}
    >
      {renderLeftIcon()}
      {renderButton()}
      {renderRightIcon()}
    </BaseButton>
  );
};

export default Button;
