import cx from "classnames";
import React from "react";
import { Spinner } from "reactstrap";
import classes from "./styles.module.scss";

type Props = {
  className?: string;
  wrapperClassName?: string;
};

const Loader: React.FC<Props> = ({ className, wrapperClassName = "" }) => {
  return (
    <div className={cx(classes.wrapper, wrapperClassName)}>
      <Spinner className={cx(classes.spinner, className)} />
    </div>
  );
};

export default Loader;
