import { Container } from "reactstrap";
import classes from "./styles.module.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container>
      <div className={classes.footer}>
        Copyrights © {year} Vaibhav International All rights reserved.
      </div>
    </Container>
  );
};

export default Footer;
