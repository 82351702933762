import { Container } from "reactstrap";
import ServicesSection from "src/assets/images/ServicesSection.jpg";
import aeroplane from "src/assets/svg/aeroplane.svg";
import bag from "src/assets/svg/bag.svg";
import box from "src/assets/svg/box.svg";
import international from "src/assets/svg/international.svg";
import ship from "src/assets/svg/ship.svg";
import vehicle from "src/assets/svg/vehicle.svg";
import Hero from "src/components/Hero";
import classes from "./styles.module.scss";

const Services = () => {
  return (
    <>
      <Hero
        title={"Effortless Global Shipping Solutions"}
        description={
          "From express document deliveries to doorstep parcel services, Vaibhav International offers reliable solutions backed by years of expertise in global logistics."
        }
        image={ServicesSection}
      />
      <Container className={classes.container}>
        <div className={classes.Service}>
          <div className={classes.content}>
            <h4 className={classes.title}>Our Services</h4>
            <div className={classes.description}>
              <div className={classes.serviceContent}>
                <div className={classes.serviceTitleContainer}>
                  <div>
                    <img
                      src={aeroplane}
                      alt="aeroplane"
                      className={classes.servicesIcon}
                    />
                  </div>
                  <h6 className={classes.serviceTitle}>
                    Air Shipping Services
                  </h6>
                </div>
                <p className={classes.serviceDescription}>
                  Our air shipping services offer swift and efficient
                  transportation of goods via air freight, ensuring timely
                  delivery to destinations across the globe. With access to a
                  vast network of airlines and logistics partners, we guarantee
                  reliable service and expedited transit times for your
                  shipments.
                </p>
              </div>
              <div className={classes.serviceContent}>
                <div className={classes.serviceTitleContainer}>
                  <div>
                    <img
                      src={ship}
                      alt="ship"
                      className={classes.servicesIcon}
                    />
                  </div>
                  <h6 className={classes.serviceTitle}>
                    Sea Shipping Services
                  </h6>
                </div>
                <p className={classes.serviceDescription}>
                  Our sea shipping services provide cost-effective
                  transportation solutions for large or bulky shipments via sea
                  freight. Leveraging strategic partnerships with major shipping
                  lines, we offer flexible routing options, competitive rates,
                  and comprehensive tracking capabilities to meet your ocean
                  freight needs with efficiency and reliability.
                </p>
              </div>
              <div className={classes.serviceContent}>
                <div className={classes.serviceTitleContainer}>
                  <div>
                    <img src={box} alt="box" className={classes.servicesIcon} />
                  </div>
                  <h6 className={classes.serviceTitle}>Courier Services</h6>
                </div>
                <p className={classes.serviceDescription}>
                  Our courier services cater to the rapid delivery of parcels,
                  documents, and small packages to local and international
                  destinations. With a focus on speed, security, and
                  convenience, we offer door-to-door pickup and delivery,
                  real-time tracking, and personalized customer support to
                  ensure a seamless shipping experience for businesses and
                  individuals alike.
                </p>
              </div>
              <div className={classes.serviceContent}>
                <div className={classes.serviceTitleContainer}>
                  <div>
                    <img src={bag} alt="bag" className={classes.servicesIcon} />
                  </div>
                  <h6 className={classes.serviceTitle}>E-Commerce Solution</h6>
                </div>
                <p className={classes.serviceDescription}>
                  Our e-commerce solutions provide end-to-end logistics support
                  for online businesses, including order fulfilment, inventory
                  management, and last-mile delivery services. With scalable
                  solutions tailored to the needs of e-commerce merchants, we
                  help optimize supply chain operations and enhance the customer
                  shopping experience through efficient and reliable shipping
                  services.
                </p>
              </div>
              <div className={classes.serviceContent}>
                <div className={classes.serviceTitleContainer}>
                  <div>
                    <img
                      src={international}
                      alt="international"
                      className={classes.servicesIcon}
                    />
                  </div>
                  <h6 className={classes.serviceTitle}>
                    International Services
                  </h6>
                </div>
                <p className={classes.serviceDescription}>
                  Our international courier service specializes in the seamless
                  transportation of goods and documents across borders,
                  connecting businesses and individuals worldwide. Whether
                  you're shipping to major cities or remote destinations, we
                  offer fast, secure, and trackable delivery options, supported
                  by customs clearance expertise and dedicated customer service
                  to ensure hassle-free international shipping.
                </p>
              </div>
              <div className={classes.serviceContent}>
                <div className={classes.serviceTitleContainer}>
                  <div>
                    <img
                      src={vehicle}
                      alt="vehicle"
                      className={classes.servicesIcon}
                    />
                  </div>
                  <h6 className={classes.serviceTitle}>Cargo Services</h6>
                </div>
                <p className={classes.serviceDescription}>
                  Our cargo services encompass a wide range of freight solutions
                  tailored to the unique requirements of businesses across
                  various industries. From consolidated shipments to
                  full-container loads, we offer comprehensive cargo management
                  services, including freight forwarding, warehousing,
                  distribution, and logistics consulting, to optimize supply
                  chain efficiency and maximize cost savings for our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Services;
