import React from "react";
import classes from "./styles.module.scss";
import { Props } from "./types";

const TableBody: React.FC<Props> = ({ row, handleRowClick }) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell, index: React.Key | null | undefined) => {
        return (
          <td
            {...cell.getCellProps()}
            key={index}
            className={classes[cell.column.id]}
            onClick={() => {
              if (handleRowClick) {
                handleRowClick(cell);
              }
            }}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export default TableBody;
