/* eslint-disable react/no-multi-comp */
import cx from "classnames";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import Button from "../Button";
import Input from "../Input";
import Loader from "../Loader";
import Pagination from "./Pagination";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import classes from "./styles.module.scss";
import { Props, SearchProps } from "./types";

const Search = (
  props: SearchProps & { handleSearchTextChange: (text: string) => void }
) => {
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    // persisting event as we will make a call after 500ms
    e.persist();
    delayedSearch(e);
  };

  const searchInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    // actual call to the search will be made from here
    props.handleSearchTextChange(e.target.value);
    props.onSearch(e.target.value);
  };

  // debouncing search function so that we don't make a call on each key down event
  const delayedSearch = debounce(searchInputChanged, 500);
  return (
    <Input
      inputGroupClassName={classes.searchInput}
      onChange={handleSearch}
      placeholder={props.searchPlaceholder}
    />
  );
};

const Table: React.FC<Props> = ({
  columns,
  data = [],
  loading = false,
  tableHeaderText = "",
  showHeaderButton = false,
  showHeaderButtonText = "See all",
  handleSort = () => {},
  noDataIndication = (
    <div className="d-flex  justify-content-center py-4">
      No data available !!
    </div>
  ),
  noSearchResults = (
    <div className="d-flex  justify-content-center py-4">No Results Found</div>
  ),
  handleRowClick,
  handleHeaderButton,
  tableHeaderClassname = "",
  showPagination = false,
  search = false,
  searchProps = {
    onSearch: () => {},
    searchPlaceholder: "",
  },
  onPageChange,
  paginationOption,
}) => {
  const {
    from = 1,
    to = 10,
    currentPage = 0,
    totalItems = 0,
    itemPerPage = 10,
  } = paginationOption || {};

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });
  const [pageIndex, setPageIndex] = useState(currentPage);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (onPageChange) {
      onPageChange(pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  const handlePageChange = (
    pageNumber: number | ((pageIndex: number) => number)
  ) => {
    setPageIndex(Number(pageNumber) - 1);
  };
  const handleSearchTextChange = (text: string) => setSearchText(text);

  const onNextHandler = () => {
    setPageIndex(pageIndex + 1);
  };

  const onPreviousHandler = () => {
    setPageIndex(pageIndex - 1);
  };

  return (
    <div className={classes.wrapper}>
      {tableHeaderText && (
        <div className={classes.tableHeader}>
          <div className={classes.tableHeading}>
            <p className="mb-0">{tableHeaderText}</p>
            {showHeaderButton && (
              <div className={classes.seeAll}>
                <Button
                  onClick={handleHeaderButton}
                  buttonText={showHeaderButtonText}
                  size="small"
                />
              </div>
            )}
          </div>
          {search && (
            <div className={classes.search}>
              <Search
                handleSearchTextChange={handleSearchTextChange}
                {...searchProps}
              />
            </div>
          )}
        </div>
      )}
      <div className={classes.tableWrapper}>
        <table {...getTableProps()}>
          <thead
            className={cx(classes.cursorPointer, {
              [tableHeaderClassname]: tableHeaderClassname,
            })}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, index) => (
                  <TableHeader
                    key={index}
                    column={column}
                    index={index}
                    handleSort={handleSort}
                  />
                ))}
              </tr>
            ))}
          </thead>
          {!loading && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i: React.Key | null | undefined) => {
                prepareRow(row);
                return (
                  <TableBody
                    key={i}
                    row={row}
                    handleRowClick={(cell) => {
                      if (handleRowClick) {
                        return handleRowClick(cell);
                      }
                    }}
                  />
                );
              })}
            </tbody>
          )}
        </table>
        {loading ? (
          <Loader wrapperClassName={classes.loader} />
        ) : data?.length === 0 ? (
          search && searchText ? (
            noSearchResults
          ) : (
            noDataIndication
          )
        ) : (
          <></>
        )}
        {!!data.length && !loading && showPagination && (
          <Pagination
            onPreviousHandler={onPreviousHandler}
            onNextHandler={onNextHandler}
            handlePageChange={handlePageChange}
            pageSize={itemPerPage}
            pageIndex={currentPage + 1}
            from={from}
            to={to}
            length={totalItems}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
